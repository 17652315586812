/* :root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */

body {
  font-family: "Rubik", sans-serif !important;
}
body::-webkit-scrollbar {
  display: none;
}
.markdown-desc p:not(:last-child) {
  margin-bottom: 10px;
}

/* home */
.banner-title::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #fff;
  z-index: 1;
  width: 100px;
  bottom: -24px;
  left: 0;
}

.tab_image_wrap {
  height: 100px !important;
}

.chakra-icon {
  fill: #627792;
}

.backgroundColor {
  background-color: #231834;
}

.technologies_list th,
.technologies_list tbody td {
  border-bottom: unset;
}
.technologies_list tbody td:last-child {
  border-right: unset;
}
.technologies_info h1,
.technologies_info h2,
.technologies_info h3,
.technologies_info h4,
.technologies_info h5,
.technologies_info h6,
.technologies_info p,
.technologies_info ul {
  font-size: revert !important;
  font-weight: revert !important;
  margin: revert !important ;
  padding: revert !important;
}

.blog_image_detail {
  margin-bottom: 16px;
  margin: auto;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 0.5rem;
}
.blogcard_detail a {
  display: flex;
  align-items: center;
  height: 68px;
}
.starCard:hover img {
  transform: scale(0.8);
  transition: 0.3s ease-in-out;
}

.dropDown::after {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  transform: translateX(-50%) translateY(6px) rotate(-45deg);
}

.weekCard:last-child .lastChild::before {
  content: unset;
}

.brandsslider-wrapper .slick-track {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.brandsslider-wrapper .slick-track .slick-slide {
  padding: 0 20px;
}

.technologies_tabs_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.portfolio_info p {
  font-size: 18px;
  line-height: 30px;
  color: #627792;
  letter-spacing: 0.8px;
}

/* -----SimpleCard Flex ChakraUi----- */
#simpleCard {
  margin: 1rem !important;
}
/* -----SimpleCard Flex ChakraUi----- */

@media only screen and (max-width: 992px) {
  .weekCard .lastChild::before {
    content: unset;
  }
  .weekCard img {
    margin: auto !important;
  }

  .vacancyOpen table,
  .vacancyOpen thead,
  .vacancyOpen tbody,
  .vacancyOpen th,
  .vacancyOpen td,
  .vacancyOpen tr {
    display: block;
  }
  .vacancyOpen thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .vacancyOpen tr:nth-child(odd) {
    background: #ccc;
  }
  .vacancyOpen td {
    position: relative;
    padding-left: 45%;
  }
  .vacancyOpen td:before {
    position: absolute;
    top: 17px;
    left: 15px;
    white-space: nowrap;
  }
  .vacancyOpen td:nth-of-type(1):before {
    content: "DEVELOPERS";
  }
  .vacancyOpen td:nth-of-type(2):before {
    content: "EXPERIENCE";
  }
  .vacancyOpen td:nth-of-type(3):before {
    content: " VACANCIES";
  }
  .vacancyOpen td:nth-of-type(4):before {
    content: " QUALIFICATION";
  }
  .vacancyOpen td:nth-of-type(5):before {
    content: "";
  }
}
.phone_input .form-control {
  width: 100% !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex !important;
  position: relative !important;
  isolation: isolate !important;
  border-color: #e0e1e7 !important;
}
.phone_input input {
  width: 100%;
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-size: var(--chakra-fontSizes-lg);
  -webkit-padding-start: var(--chakra-space-10);
  padding-inline-start: var(--chakra-space-10);
  -webkit-padding-end: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  height: var(--chakra-sizes-12) !important;
  border-radius: var(--chakra-radii-md);
  border: 1px solid;
  border-color: inherit;
  background: inherit;
}
.phone_input input:focus-visible,
.phone_input input[data-focus-visible] {
  border-color: #3182ce;
  box-shadow: 0 0 0 1px #3182ce;
}
.flag-dropdown {
  background-color: transparent !important;
  border: 0 !important;
  border-radius: 0 !important;
}
.selected-flag:hover,
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: inherit !important;
}
@media screen and (max-width: 767px) {
  #chakra-modal-form_modal {
    height: 100vh;
  }
}

@media only screen and (max-width: 575px) {
  .bottom-banner .all-banner-title {
    font-size: 24px;
  }
  .technologies_tabs_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
}

@media only screen and (max-width: 320px) {
  .technologies_tabs_wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
